<template>
  <div class="footer-wrapper">
    <div v-if="isMobile" class="flex">
      <svg-icon @click="toWebsite(faceBookUrl)"
                class="mr-[8px] cursor-pointer"
                name="fb"
                size="30"/>
      <svg-icon @click="toWebsite(lineUrl)"
                class="cursor-pointer"
                name="line"
                size="30"/>
    </div>
    <div>
      <div @click="go('/')" class="cursor-pointer ml-5 mb-[30px] flex justify-center">
        <img src="../../assets/image/logo/osn-zh-horizon.png"
             width="200"
             alt="">
      </div>
      <ul class="sub-menu">
        <li @click="toWebsite(hostUrl)">
          {{ $t('menu.owner_index') }}
        </li>
        <li @click="toWebsite(helpUrl)">
          {{ $t('menu.help_center') }}
        </li>
        <li @click="toWebsite(lineUrl)">
          {{ $t('menu.line') }}
        </li>
        <li @click="toWebsite(privacyUrl)">
          {{ $t('menu.privacy_policy') }}
        </li>
        <li @click="toWebsite(clientUrl)">
          {{ $t('menu.contract_terms') }}
        </li>
      </ul>
      <ul class="company">
        <li>Copyright © 2024 One Step Technology Limited Company. All Rights Reserved.</li>
        <li>一步科技有限公司 統一編號：90041170</li>
      </ul>
    </div>
    <div v-if="!isMobile" class="flex">
      <svg-icon @click="toWebsite(faceBookUrl)"
                class="mr-[8px] cursor-pointer"
                name="fb"
                size="30"/>
      <svg-icon @click="toWebsite(lineUrl)"
                class="cursor-pointer"
                name="line"
                size="30"/>
    </div>

  </div>
</template>
<script setup>
import {computed, ref} from 'vue'
// import {useGoTo} from "@/composables/useGoTo";
// import {useSettingStore} from "@/stores/setting.js";


const {toWebsite,go} = useGoTo()
const settingStore = useSettingStore()
const isMobile = computed(() => settingStore.isMobile);

const helpUrl = ref(import.meta.env.VITE_APP_BASE_HELP_URL)
const lineUrl = ref(import.meta.env.VITE_APP_BASE_LINE_URL)
const hostUrl = ref(import.meta.env.VITE_APP_BASE_HOST_URL)
const clientUrl = ref(import.meta.env.VITE_APP_BASE_CLIENT_URL)
const privacyUrl = ref(import.meta.env.VITE_APP_BASE_PRIVACY_URL)
const faceBookUrl = ref(import.meta.env.VITE_APP_BASE_FACEBOOK_URL)

</script>

<style lang="scss"
       scoped>
.footer-wrapper {
  @apply flex border-t
  pc:pt-[50px] pc:pb-[45px] pc:justify-around
  mobile:mobile:mb-[25px] mobile:flex-col mobile:items-center mobile:justify-center;

  >div{
    @apply mt-[20px];
  }
  ul.sub-menu {
    @apply flex
    pc:justify-between
    mobile:px-[30px] mobile:flex-wrap mobile:justify-center mobile:text-center;

    li{
      @apply  cursor-pointer
      mobile:mb-[20px] mobile:w-1/3;
    }
  }

  ul.company {
    @apply text-[12px] text-osn-dark-grey-43
    pc:mt-[19px];
    li {
      @apply mb-[8px]
      mobile:px-[35px] mobile:text-center;
    }

    li:last-child {
      @apply m-0;
    }
  }

}
</style>
